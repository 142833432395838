import './index.css'
import React from 'react';
import { Routes, Route} from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import Contact from './Pages/Contact/Contact'
import Solutions from './Pages/Solutions/Sollution'
import DigitalMarketing from './Pages/Solutions/DigitalMarketing/DigitalMarketing'
import WebDevelopment from './Pages/Solutions/WebDevelopment/WebDevelopment'
import AppDevelopment from './Pages/Solutions/AppDevelopment/AppDevelopment';
import Maintainance from './Pages/Solutions/Maintainance/Maintainance';
import AiBased from './Pages/Solutions/AiBased/AiBased';
import SpecificMember from './Pages/SpecificMemberDetails/SpecificMember';


function App() {
  return (
    <>
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/solutions" element={<Solutions />} />
        <Route exact path="/digital-marketing" element={<DigitalMarketing />} />
        <Route exact path="/web-development" element={<WebDevelopment />} />
        <Route exact path="/app-development" element={<AppDevelopment />} />
        <Route exact path="/maintainance" element={<Maintainance />} />
        <Route exact path="/ai-based-solutions" element={<AiBased />} />
        <Route path="/team/:id" element={<SpecificMember />} />
      </Routes>
      <Footer/>
    </>
  );
}
export default App;


