import DigitalMarketing from '../../Assets/Services/DigitalMarketing.svg'
import WebDevelopment from '../../Assets/Services/WebDevelopment.svg'
import AppDevelopment from '../../Assets/Services/AppDevelopment.svg'
import AIBasedSolutions from '../../Assets/Services/AIBasedSolutions__1.png'
import DomainHosting from '../../Assets/Services/DomainHosting.svg'
import Maintenance from '../../Assets/Services/Maintenance.svg'

const Services = [
    {
        img: DigitalMarketing,
        title: "Digital Marketing",
        alt:"DigitalMarketing",
        descrition: "Boost your revenue fast with smart digital marketing. Avoid costly mistakes—partner with us for winning results.",
        link: "/digital-marketing"

    },
    {
        img: WebDevelopment,
        title: "Web Development",
        alt:"WebDevelopment",
        descrition: "Dynamic Leo creates stunning, high impact websites that captivate audience and drive your success with cutting-edge web development.",
        link: "/web-development"

    },
    {
        img: AppDevelopment,
        title: "App Development",
        alt:"AppDevelopment",
        descrition: "We deliver essential mobile app solutions that amplify your brand's reach and boost exposure.",
        link: "/app-development"
    },
    {
        img: AIBasedSolutions,
        title: "AI Based Solutions",
        alt:"AIBasedSolutions",
        descrition: "Tap into the power of AI effortlessly with our solutions, achieving seamless integration without the hassle.",
        link: "/ai-based-solutions"
    },
    {
        img: DomainHosting,
        title: "Domain & Hosting",
        alt:"DomainHosting",
        descrition: "Whether starting up or scaling up, trust us for web hosting and domains, so you can focus on what's crucial.",
        link: "/domain-and-hosting"

    },
    {
        img: Maintenance,
        title: "Maintenance",
        alt:"Maintenance",
        descrition: "Additionally, we offer ongoing maintenance services, letting you focus on expanding your business with ease and precision.",
        link: "/maintainance"
    }
]

export default Services;