import AIAutomatiosolutions from "../../Assets/solutions/aiBased/AI-Automation-solutions.svg";
import MachineLearningModels from "../../Assets/solutions/aiBased/MachineLearningModels.svg";
import ChatbotDevelopment from "../../Assets/solutions/aiBased/ChatbotDevelopment.svg";

const AiBased = [
  {
    img: AIAutomatiosolutions,
    title: "AI-Automation solutions",
    alt: "AI-Automation-solutions",
  },
  {
    img: MachineLearningModels,
    title: "Machine Learning Models",
    alt: "Machine-Learning-Models",
  },
  {
    img: ChatbotDevelopment,
    title: "Chatbot Development",
    alt: "ChatbotDevelopment",
  },
];

export default AiBased;
