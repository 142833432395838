import Analysis from '../../Assets/solutions//WebDevelopment/Analysis.svg'
import Design from '../../Assets/solutions//WebDevelopment/Design.svg'
import Development from '../../Assets/solutions//WebDevelopment/Development.svg'
import Testing from '../../Assets/solutions//WebDevelopment/Testing.svg'
import Deployment from '../../Assets/solutions//WebDevelopment/Deployment.svg'
import Maintenance from '../../Assets/solutions//WebDevelopment/Maintenance.svg'

const WDWork = [
    {
        img: Analysis,
        title: "Analysis",
        alt:"Analysis",
        descrition: "Analysis is where we delve into your requirements to craft a solution specifically tailored to your unique needs."

    },
    {
        img: Design,
        title: "Design",
        alt:"Design",
        descrition: "Design is where we transform your concepts into a sleek, user-friendly digital experience that elevates your brand."

    },
    {
        img: Development,
        title: "Development",
        alt:"Development",
        descrition: "Development is where we translate your vision into a robust, scalable, and secure digital solution that powers your business."
    },
    {
        img: Testing,
        title: "Testing",
        alt:"Testing",
        descrition: "Testing guarantees your site functions perfectly, ensuring a smooth user experience."

    },
    {
        img: Deployment,
        title: "Deployment",
        alt:"Deployment",
        descrition: "Deployment brings your product to life, making it accessible to your target audience."

    },
    {
        img: Maintenance,
        title: "Maintenance",
        alt:"Maintenance",
        descrition: "Maintenance keeps your site updated, secure, and performing at its best."
    }
]

export default WDWork;