import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Community() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    const handleLinkClick = () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };
    return (
        <div className='w-full bg-[#FFEABD] overflow-hidden'>
            <div className='w-full sectionlayout poppins'>
                <div className='mt-8 py-16 flex flex-col items-center justify-center' data-aos="flip-right" >
                    <h6 className='bg-gradient-to-r from-[#F857A6] to-[#EF3F6E] bg-clip-text text-transparent text-center mb-3 uppercase font-bold'>Work with Us</h6>
                    <p style={{ fontSize: 'clamp(20px,2.2vw,38px)' }} className='text-[#343F52] text-center w-[80%] md:w-[60%] mx-auto mb-3 font-bold'>Discuss your business ideas with our team and <br /> explore innovative solutions crafted for your needs.</p>
                    <Link to="/contact" onClick={handleLinkClick}>
                        <button className='py-[15px] px-[20px] bg-gradient-to-r from-[#F857A6] to-[#EF3F6E] text-white text-[15px] md:text-[16px] rounded-[8px] font-semibold mr-6 block mb-4 md:mb-0'>Get in Touch</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Community
