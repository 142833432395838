import React from 'react'
import Hero from './Hero'
import Content from './Content'

function Contact() {
  return (
    <>
    <Hero/>
    <Content/>
    </>
  )
}

export default Contact