import React from 'react'
import Solutions from './Solutions'
import Community from '../Home/Community'

function Sollution() {
  return (
    <>
    <p style={{ fontSize: 'clamp(25px,4vw,60px)' }} className='text-[#DF5932] font-bold poppins text-center mt-5'>Solutions</p>
    <Solutions/>
    <Community/>
    </>
  )
}

export default Sollution