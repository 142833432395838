import ProactiveSystemMonitoring from '../../Assets/solutions/Maintainance/ProactiveSystemMonitoring.svg'
import RegularUpdatesandUpgrades from '../../Assets/solutions/Maintainance/RegularUpdatesandUpgrades.svg'
import TechnicalSupport from '../../Assets/solutions/Maintainance/TechnicalSupport.svg'
import SystemOptimization from '../../Assets/solutions/Maintainance/SystemOptimization.svg'
import Data_Integrity_Security from '../../Assets/solutions/Maintainance/Data_Integrity_Security.svg'
import Customized_Maintenance_Plans from '../../Assets/solutions/Maintainance/Customized_Maintenance_Plans.svg'

const Maintainance = [
    {
        img: ProactiveSystemMonitoring,
        title: "Proactive System Monitoring",
        alt:"ProactiveSystemMonitoring"

    },
    {
        img: RegularUpdatesandUpgrades,
        title: "Regular Updates and Upgrades",
        alt:"RegularUpdatesandUpgrades"

    },
    {
        img: TechnicalSupport,
        title: "24/7 Technical Support",
        alt:"TechnicalSupport"
    },
    {
        img: SystemOptimization,
        title: "System Optimization",
        alt:"SystemOptimization"

    },
    {
        img: Data_Integrity_Security,
        title: "Data Integrity & Security",
        alt:"Data_Integrity_Security"

    },
    {
        img: Customized_Maintenance_Plans,
        title: "Customized Maintenance Plans",
        alt:"Customized_Maintenance_Plans"
    }
]

export default Maintainance;