import React,{useEffect} from 'react'
import WDWork from '../../../Data/Solutions/WebDevelopmentWork'
import AOS from 'aos';
import 'aos/dist/aos.css';
import BelowSection from './BelowSection';

function Work() {
    useEffect(() => {
        AOS.init({ duration: 1500 }); 
      }, []);
    return (
        <div style={{ background: `linear-gradient(0deg, rgba(255, 246, 234, 1) 100%, rgba(255, 255, 255, 1) 100%, rgba(255, 249, 242, 0.8966) 89.66%, rgba(255, 246, 234, 0.09) 9%)`, }} className='w-full py-8'>
            <div className='w-full sectionlayout poppins'>
                <h6 className='uppercase font-semibold text-[16px] text-[#DF5932] text-center pb-3'>What We Offer</h6>
                <p style={{ fontSize: 'clamp(22px,2.5vw,42px)' }} className='font-semibold text-[#491609] text-center w-[80%] md:w-[60%] mx-auto '>Innovative designs that amplify your brand's impact.</p>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8 gap-5'>
                    {WDWork.map((service, index) => (
                        <div key={index} className='px-4 md:px-8 bg-white rounded-xl drop-shadow-lg' data-aos="fade-up" >
                            <div className='flex items-center justify-center pt-2'>
                                <img src={service.img} alt={service.alt} className='h-[300px]' />
                            </div>
                            <p style={{ fontSize: 'clamp(18px,1.5vw,23px)' }} className='font-semibold text-[#DF5932] text-center mt-5 mb-2'>{service.title}</p>
                            <p style={{ fontSize: 'clamp(15px,1.5vw,17px)' }} className='font-medium text-[#6c6c6c] text-center pb-8'>{service.descrition}</p>
                        </div>
                    ))}
                </div>
            <BelowSection />

            </div>

        </div>
    )
}

export default Work