import img1 from '../../Assets/solutions/AppDevelopment/UX.svg'
import img2 from '../../Assets/solutions/AppDevelopment/Performance.svg'
import img3 from '../../Assets/solutions/AppDevelopment/Application.jpg'

const AppDevelopment = [
    {
        img: img1,
        title: "UI/UX Creative Custom Design",
        alt:"UI/UX-Creative-Custom-Design"

    },
    {
        img: img2,
        title: "Performance Optimization",
        alt:"Performance-Optimization"

    },
    {
        img: img3,
        title: "Application Maintenance",
        alt:"Application-Maintenance"
    }
]

export default AppDevelopment;