import React from 'react'
import Hero from './Hero'
import Solutions from '../Solutions/Solutions'
import VisionAndMission from './VisionAndMission'
import Community from './Community'

function Home() {
  return (
    <>
      <Hero />
      <Solutions />
      <VisionAndMission />
      <Community />
    </>
  )
}

export default Home
