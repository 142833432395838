import React, {useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact_Arrow from '../../Assets/icons/contact_arrow.svg'
import Contact_Aeroplane from '../../Assets/icons/contact/aeroplane.svg'
import location from '../../Assets/icons/contact/location.svg'
import contact from '../../Assets/icons/contact/contact.svg'
import email from '../../Assets/icons/contact/email.svg'

function Content() {
    useEffect(() => {
        AOS.init({ duration: 1000 }); 
      }, []);
    return (
        <div className='w-full overflow-hidden'>
            <div className='w-full sectionlayout poppins'>
                <div className='flex items-center justify-center w-full relative pt-[4rem]' data-aos="fade-right" data-aos-delay="500">
                    <img src={Contact_Arrow} alt="Contact Us Arrows" className='absolute left-0 h-[4rem] w-[3.5rem] md:h-auto md:w-auto' />
                </div>
                <div className='md:flex py-[3rem] md:py-[7rem] border-b'>
                    <div className='md:w-1/2 mb-[3rem] md:mb-0 flex items-center justify-center md:items-start' data-aos="fade-up">
                        <div className='w-[97%] md:w-auto'>
                            <h4 style={{ fontSize: 'clamp(20px,3vw,45px)' }} className='text-[#DF5932] font-bold leading-tight'>Share your vision and voice concerns.</h4>
                            <p style={{ fontSize: 'clamp(17px,1.5vw,20px)' }} className='text-[#6c6c6c] w-[75%] pl-1'>Got project ideas or feedback? Drop us a message and we'll get back to you shortly.</p>
                            <div className='w-full flex items-center pt-3'>
                                <img src={location} alt="location" className='w-[1.7rem]' />
                                <p className='pl-2 md:pl-4 pt-2 text-[#535D61] font-bold'>Gulberg Green Islamabad</p>
                            </div>
                            <div className='w-full flex items-center pt-3'>
                                <img src={contact} alt="contact" className='w-[1.7rem]' />
                                <p className='pl-2 md:pl-4 pt-2 text-[#535D61] font-bold'>+92 304 1110898</p>
                            </div>
                            <div className='w-full flex items-center pt-3'>
                                <img src={email} alt="email" className='w-[1.7rem]' />
                                <p className='pl-2 md:pl-4 pt-2 text-[#535D61] font-bold'>info@dynamicleo.com</p>
                            </div>
                        </div>
                    </div>
                    <div className='md:w-1/2 flex items-center justify-center relative mt-[6.5rem] md:mt-0'>
                        <div className='absolute -top-[20%] md:-top-[25%] lg:-top-[30%] left-[40%] z-[1000]' data-aos="fade-down">
                            <img src={Contact_Aeroplane} alt="contact Aeroplane" className='' />
                        </div>
                        <div className='hidden md:block absolute bottom-0 -left-[20%]' data-aos="fade-right">
                            <img src={Contact_Arrow} alt="Contact Arrow" className='' />
                        </div>
                        <div className="bg-gradient-to-b via-50% from-[#FFE985] to-[#FA742B] inline-block w-[97%] md:w-[80%] rounded-xl" data-aos="fade-up">
                            <form className='px-4 md:px-8 py-12'>
                                <div className='lg:flex gap-4'>
                                    <input type="text" placeholder='First Name' required className='w-full p-3 md:p-4 rounded-xl border hover:outline-[#FF8438] focus:outline focus:outline-[#FF8438] focus:outline-2 mb-6 lg:mb-0' />
                                    <input type="text" placeholder='Last Name' required className='w-full p-3 md:p-4 rounded-xl border hover:outline-[#FF8438] focus:outline focus:outline-[#FF8438] focus:outline-2' />
                                </div>
                                <div className='pt-6'>
                                    <input type="text" placeholder='Email' required className='w-full p-3 md:p-4 rounded-xl border hover:outline-[#FF8438] focus:outline focus:outline-[#FF8438] focus:outline-2' />
                                </div>
                                <div className='pt-6'>
                                    <input type="text" placeholder='Phone ' required className='w-full p-3 md:p-4 rounded-xl border hover:outline-[#FF8438] focus:outline focus:outline-[#FF8438] focus:outline-2' />
                                </div>
                                <div className='pt-6'>
                                    <textarea
                                        placeholder="Your Message"
                                        rows="6"
                                        required
                                        className="w-full p-3 md:p-4 rounded-xl border hover:outline-[#FF8438] focus:outline focus:outline-[#FF8438] focus:outline-2"
                                    ></textarea>
                                </div>
                                <div className='pt-6'>
                                    <button style={{ fontSize: 'clamp(16px,1.2vw,18px)' }} className='w-full py-3 bg-gradient-to-br from-[#FEC163] to-[#DE4313] via-50% rounded-[24px] text-white font-semibold'>Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content