import Analytics from "../../Assets/solutions/DigitalMarketing/Analytics.png";
import ContentMarketing from "../../Assets/solutions/DigitalMarketing/ContentMarketing.png";
import PPC from "../../Assets/solutions/DigitalMarketing/PPC.png";
import EmailMarketing from "../../Assets/solutions/DigitalMarketing/EmailMarketing.png";
import SEO from "../../Assets/solutions/DigitalMarketing/SEO.png";
import SocialMedia from "../../Assets/solutions/DigitalMarketing/SocialMedia.png";

const DMWork = [
  {
    img: SEO,
    title: "Search Engine Optimization (SEO)",
    alt: "Search Engine Optimization (SEO)",
    descrition:
      "Enhancing website visibility on search engines to drive organic traffic.",
    tools: "Google Search Console, SEMrush",
  },
  {
    img: SocialMedia,
    title: "Social Media Marketing and Advertisement",
    alt: "Social Media Marketing",
    descrition:
      "Managing and optimizing social media platforms to increase brand engagement and reach.",
    platforms: "Meta Ads Manager, Linkedin Marketing, Tiktok Marketing",
  },
  {
    img: ContentMarketing,
    title: "Content Marketing",
    alt: "Content Marketing",
    descrition:
      "Creating and distributing valuable content to attract and retain a target audience.",
    tools: "WordPress, Canva, HubSpot, Grammarly",
  },
  {
    img: EmailMarketing,
    title: "Email Marketing",
    alt: "Email Marketing",
    descrition:
      "Designing and executing email campaigns to nurture leads and drive conversions. ",
    tools: "Mailchimp, Constant Contact, Sendinblue, Campaign Monitor",
  },
  {
    img: PPC,
    title: "Pay-Per-Click (PPC) Advertising",
    alt: "PPC",
    descrition:
      "Running paid ad campaigns to generate immediate traffic and leads.",
    platforms: "Google Ads, Bing Ads, Facebook Ads, Keyword Planner",
  },
  {
    img: Analytics,
    title: "Analytics and Reporting",
    alt: "Analytics and Reporting",
    descrition:
      "Monitoring and analyzing marketing performance to optimize strategies. ",
    tools: ["Google Analytics", "Data Studio"],
  },
];

export default DMWork;
