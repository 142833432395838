import React, { useEffect } from "react";
import globe from "../../../Assets/solutions/DigitalMarketing/Globe.svg";
import Shape from "../../../Assets/solutions/DigitalMarketing/Shape.svg";
import marketing from "../../../Assets/solutions/DigitalMarketing/marketing.png";

import AOS from "aos";
import "aos/dist/aos.css";

function BelowSection() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div
      style={{
        background: "linear-gradient(135deg, #52E5E7 0%, #130CB7 100%)",
      }}
      className="w-full  my-12 overflow-hidden rounded-[10px] "
    >
      <div className="w-full  sectionlayout poppins relative">
        <img
          src={globe}
          alt="Globe"
          className="absolute top-[-80px] left-[-80px]  opacity-0 md:opacity-50 lg:opacity-100"
        />
        <h2
          className="uppercase font-semibold text-[16px] mx-auto lg:w-[50%] text-white text-center pb-3 pt-12"
          style={{ fontSize: "clamp(22px,2.5vw,42px)" }}
        >
          Reach your audience and convert your leads
        </h2>
        <div className="flex flex-col space-y-8 md:space-y-0 justify-center items-center md:flex-row py-6">
          <div className="w-full md:w-1/2">
            <p
              style={{ fontSize: "clamp(16px,1.5vw, 20px)" }}
              className="font-medium leading-loose text-center md:text-left text-white  w-[80%] md:w-[56%] mx-auto "
            >
              Boost your social media engagement with our great social media
              marketing.
            </p>
          </div>
          <div className="w-full md:w-1/2 flex justify-center items-center">
            <img
              src={marketing}
              alt="Marketing"
              className="w-[80%] max-h-[70vh]"
            />
          </div>
        </div>

        <img
          src={Shape}
          alt="Shape"
          className="absolute bottom-[-10px] left-[-10px] opacity-0 md:opacity-50 lg:opacity-100"
        />
      </div>
    </div>
  );
}

export default BelowSection;
