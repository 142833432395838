import React,{useEffect} from "react";
import hero from '../../../Assets/solutions/Maintainance/Maintainance.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
const BelowSection = () => {

    useEffect(() => {
        AOS.init({ duration: 1000 }); 
      }, []);
  return (
    <div
      className="my-16 py-16  rounded-[20px] overflow-hidden poppins"
      style={{ background: "linear-gradient(135deg, #FFCF71 0%, #2376DD 100%)"}}
    >
        <h2 className="capitalize font-bold text-center  text-white" style={{ fontSize: 'clamp(22px,2.5vw,42px)' }}
        data-aos='fade-up'
        >Ensuring Your IT Systems Run <br /> Smoothly</h2>
        <div className="flex flex-col-reverse  md:flex-row items-center justify-center  mx-4 md:mx-10 mt-10 "  data-aos='fade-up'>
        <div className="w-full md:w-[50%] flex justify-center items-center mt-8 md:mt-0"  data-aos='fade-left'>

<p style={{ fontSize: 'clamp(20px,1.5vw,27px)' }} className=' text-white text-center  md:text-left  md:w-[70%]  leading-10 pb-8'>Dynamic Leo ensures your IT systems stay efficient and secure with proactive monitoring and customized maintenance plans, minimizing downtime and disruptions.</p>

</div>
        <div className="w-full md:w-[50%] flex justify-center"  data-aos='fade-right'>
            <img src={hero} alt="Web Development" className="max-h-[60vh]"/>

        </div>

       
        </div>
    </div>
  );
};

export default BelowSection;
