import React, {useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import img from '../../Assets/About/about.svg'

function Description() {
    useEffect(() => {
        AOS.init({ duration: 1000 }); 
      }, []);
    return (
        <div className='w-full'>
            <div className='w-full sectionlayout poppins'>
                <div className='flex flex-col-reverse md:flex-row items-center justify-center gap-10 my-10'>
                <div className='md:w-1/2 flex flex-col items-center justify-center' data-aos="zoom-in">
                    <img className='w-[80%]' src={img} alt="About Company" />
                </div>
                <div className='md:w-1/2'>
                    <p style={{ fontSize: 'clamp(17px,2vw,25px)' }} className='font-medium text-[#491609]' data-aos="fade-up"><span className='text-[#E47045] font-semibold'>At Dynamic Leo, </span>we excel in the digital space by forming close partnerships with our clients and delivering customized IT solutions. We focus on understanding each client’s unique needs, fostering strong, trust-based relationships, and utilizing innovative technology to drive their success and help them reach their full potential.</p>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Description