import React,{useEffect} from "react";
import hero_img from "../../../Assets/solutions/aiBased/belowSection.png";
import herobg from "../../../Assets/Hero/aibasedbg.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const BelowSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);
  return (
    <div
      style={{
        backgroundImage: `url(${herobg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="rounded-[14px]"
    >
      <div className="my-16 flex md:flex-row flex-col-reverse items-center justify-center    text-white py-20 w-[94%]" data-aos="fade-up">
        <div className="justify-end md:flex-row flex-col flex w-[96%] md:w-[58%] mt-20 md:mt-0" data-aos="fade-right">
          <img src={hero_img} alt="AI Revolution" />
        </div>
        <div className="flex flex-col items-center  justify-center w-[96%]  md:w-[42%]" data-aos="fade-left">
          <div className="flex  items-center">
            <h2
              className="font-bold "
              style={{ fontSize: "clamp(94px,6.9vw,170px)" }}
            >
              AI
            </h2>
            <div>
              <h6
                style={{ fontSize: "clamp(36px,2.8vw,90px)" }}
                className="leading-8 lg:leading-[40px] 2xl:leading-[55px] flex flex-col mt-1.5  font-medium "
              >
                Revolution <span className="font-bold"> Starts </span>
              </h6>
            </div>
          </div>
          <div
            className="font-semibold mt-[-5px]"
            style={{ fontSize: "clamp(26px,1.3vw,60px)" }}
            data-aos="fade-up" 
          >
            <p>
            with{" "}
            <span
              className="text-[#DF5932] font-bold"
              style={{ fontSize: "clamp(30px,1.4vw,60px)" }}
            >
              Dynamic LEO
            </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BelowSection;
