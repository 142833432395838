import React, { useEffect } from 'react'
import { useLocation,Link } from 'react-router-dom';
import right_arrow_black from '../../Assets/icons/right_arrow_black.svg'
import Services from '../../Data/Solutions/Solutions'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Solutions() {
    const location = useLocation();
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    const handleLinkClick = () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };
    return (
        <>
            <div className='w-full bg-[#ffffff]'>
                <div className='w-full sectionlayout poppins'>
                    {location.pathname !== '/solutions' && (
                        <h4 className='uppercase font-semibold text-[15px] text-[#DF5932] text-center mt-8 mb-3 '>What We do ?</h4>
                    )}

                    <h6 className='font-semibold  text-[22px] md:text-[42px] text-[#6c6c6c] text-center w-[80%] md:w-[60%] mx-auto '>Uncover the solutions that will elevate your business</h6>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8 gap-5'>
                        {Services.map((service, index) => (
                            <div key={index} className='px-4 md:px-8' data-aos="fade-up" >
                                <div className='flex items-center justify-center'>
                                    <img src={service.img} alt={service.alt} className='h-[300px]' />
                                </div>
                                <h5 className='font-semibold text-[18px] md:text-[23px] text-[#6c6c6c] text-center mt-5 mb-2'>{service.title}</h5>
                                <p className='font-medium text-[15px] md:text-[17px] text-[#6c6c6c] text-center'>{service.descrition}</p>
                                <div className='mt-4'>
                                    <Link to={service.link} className='flex items-center justify-center' onClick={service.link ? handleLinkClick : null}>
                                        <p className='font-semibold text-[15px] md:text-[17px] text-[#6c6c6c] text-center mr-2'>Learn More</p>
                                        <img src={right_arrow_black} alt="Right Arrow Back" />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Solutions