import React,{useEffect} from "react";
import hero from '../../../Assets/solutions/WebDevelopment/BelowSection.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
const BelowSection = () => {

    useEffect(() => {
        AOS.init({ duration: 1000 }); 
      }, []);
  return (
    <div
      className="my-10 py-16  rounded-[20px] overflow-hidden"
      style={{ background: "linear-gradient(135deg, #F1CA74 0%, #A64DB6 100%)"}}
    >
        <h2 className="capitalize text-center font-medium text-white" style={{ fontSize: 'clamp(22px,2.5vw,42px)' }}
        data-aos='fade-up'
        >Boost Your Brand Credibility.</h2>
        <div className="flex flex-col md:flex-row items-center justify-center md:space-x-10 mx-4 md:mx-10 mt-10 "  data-aos='fade-up'>
        <div className="w-full md:w-[60%] flex justify-end"  data-aos='fade-right'>
            <img src={hero} alt="Web Development" className="max-h-[60vh]"/>

        </div>

        <div className="w-full md:w-[40%] flex justify-center items-center"  data-aos='fade-left'>

        <p style={{ fontSize: 'clamp(20px,1.5vw,27px)' }} className=' text-white text-center  md:text-left w-[80%]  leading-10 pb-8'>We offer website creation services that are creative, responsive, and according to your needs.</p>

        </div>
        </div>
    </div>
  );
};

export default BelowSection;
