import React, { useEffect } from "react";
import DMWork from "../../../Data/Solutions/DigitalMarketingWork";
import AOS from "aos";
import "aos/dist/aos.css";
import BelowSection from "./BelowSection";

function Work() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div
      style={{
        background: `linear-gradient(0deg, rgba(255, 246, 234, 1) 100%, rgba(255, 255, 255, 1) 100%, rgba(255, 249, 242, 0.8966) 89.66%, rgba(255, 246, 234, 0.09) 9%)`,
      }}
      className="w-full py-8"
    >
      <div className="w-full sectionlayout poppins">
        <h6 className="uppercase font-semibold text-[16px] text-[#DF5932] text-center pb-3">
          How It Works
        </h6>
        <p
          style={{ fontSize: "clamp(22px,2.5vw,42px)" }}
          className="font-semibold text-[#491609] text-center w-[80%] md:w-[60%] mx-auto"
        >
          Targeted Campaigns Routing Traffic Towards Your Business
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8 gap-5 ">
          {DMWork.map((service, index) => (
            <div
              key={index}
              className="px-4 md:px-8 bg-white rounded-xl pb-4 drop-shadow-lg flex flex-col items-center"
              data-aos="fade-up"
            >
              <div className="flex items-center justify-center pt-2">
                <img
                  src={service.img}
                  alt={service.alt}
                  className="h-[260px] w-auto"
                />
              </div>

              <p
                style={{ fontSize: "clamp(18px,1.5vw,23px)" }}
                className="font-semibold text-[#987423] text-center mt-5 mb-2"
              >
                {service.title}
              </p>

              <p
                style={{ fontSize: "clamp(15px,1.3vw,17px)" }}
                className="font-medium text-[#6c6c6c] text-center pb-8"
              >
                {service.descrition}
              </p>

              {service.tools && (
                <div className="w-full flex items-start ">
                  <p
                    style={{ fontSize: "clamp(18px,1.2vw,23px)" }}
                    className="font-semibold  text-[#987423] mr-2"
                  >
                    Tools:{" "}
                    <span
                      style={{ fontSize: "clamp(15px,1.2vw,20px)" }}
                      className="font-medium text-[#6c6c6c]"
                    >
                      {service.tools}
                    </span>
                  </p>
                  <div className="flex mt-1  whitespace-nowrap flex-wrap gap-2"></div>
                </div>
              )}

              {service.platforms && (
                <div className="w-full flex items-start ">
                  <p
                    style={{ fontSize: "clamp(18px,1.2vw,23px)" }}
                    className="font-semibold  text-[#987423] mr-2"
                  >
                    Platforms:
                    <span
                      style={{ fontSize: "clamp(15px,1.2vw,20px)" }}
                      className="font-medium text-[#6c6c6c]"
                    >
                      {service.platforms}
                    </span>
                  </p>
                  <div className="flex mt-1  whitespace-nowrap flex-wrap gap-2"></div>
                </div>
              )}
            </div>
          ))}
        </div>
        <BelowSection />
      </div>
    </div>
  );
}

export default Work;
