const Navbar = {
    links: [
        {
            name: "Home",
            path: "/"
        },
        {
            name: "Solutions",
            path: "/solutions",
            submenu: [
                {
                    name: "Digital Marketing",
                    path: "/digital-marketing"
                },
                {
                    name: "Website Development",
                    path: "/web-development"
                },
                {
                    name: "App Development",
                    path:"/app-development"
                },
                {
                    name: "AI Based Solutions",
                    path: "/ai-based-solutions"
                },
                {
                    name: "Domain & Hosting",
                    path: "/domain-and-hosting"
                },
                {
                    name: "Maintenance",
                    path:"/maintainance"
                }
            ]
        },
        {
            name: "About",
            path: "/about"
        },
        {
            name: "Contact",
            path: "/contact"
        }
    ]
};

export default Navbar;
