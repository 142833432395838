import React, { useEffect } from 'react'
import hero_img from '../../../Assets/Hero/aibased.png'
import herobg from '../../../Assets/Hero/aibasedbg.svg'
import greater_arrow_white from '../../../Assets//icons/greater_arrow_white.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Hero() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    return (
        <div className='w-full max-h-[100vh] overflow-hidden'>
            <div className='w-full poppins sectionlayout bg-no-repeat '
                style={{
                    backgroundImage: `url(${herobg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>
                <div className='block md:flex pt-[2rem] md:pt-[2.5rem] '>
                    <div className='md:w-1/2 ' data-aos="fade-right" >
                        <div className='text-white'>
                            <h1 style={{ fontSize: 'clamp(30px,4.5vw,70px)' }} className='uppercase font-bold  leading-tight'>Future-Ready   <span className='text-[#DF5932] '>AI from  </span> Your Team </h1>
                            <p style={{ fontSize: 'clamp(16px,1.4vw, 20px)' }} className='text-white w-[80%]  font-medium pl-1 pt-[1.5rem] md:pt-[2rem]'>Integrate AI that anticipates future trends, equipping your team with tools for sustained success and continued growth.</p>
                        </div>
                        <div className='md:flex pt-[1.5rem] md:pt-[2rem]'>
                            <button style={{ fontSize: 'clamp(15px,1.5vw,18px)' }} className='py-[12px] px-[20px] bg-gradient-to-r from-[#65FDF0] to-[#1D6FA3] text-white rounded-[8px] font-semibold mr-6 mb-4 md:mb-0'>Learn More <img src={greater_arrow_white} alt="Greater Arrow" className='pl-3 inline-block h-[15px]' /></button>
                        </div>
                    </div>
                    <div className='flex items-center justify-end md:w-1/2' data-aos="fade-up-left" >
                        <img src={hero_img} alt="Ai Based" className='w-[90%]' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero