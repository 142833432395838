import Mian_Faraz from '../Assets/teams/core/Mian_Faraz.jpg';
import TufailShah from '../Assets/teams/core/TufailShah.jpg';
import NadeemYousaf from '../Assets/teams/core/NadeemYousaf.jpg'
import AhmedRohailAwan from '../Assets/teams/team/AhmedRohailAwan.jpg';
import MuhammadMobeen from '../Assets/teams/team/MuhammadMobeen.jpg';
import AmmarZafar from '../Assets/teams/team/AmmarZafar.jpg';
import BintEZainabLaraib from '../Assets/teams/team/BintEZainabLaraib.jpg';
import ZainulAbaideen from '../Assets/teams/team/ZainulAbaideen.jpg';
import MuhammadBinNasir from '../Assets/teams/team/MuhammadBinNasir.jpg';

const CEO = [
    {
        nothing:"..."
    },
    {
        id: "mian-faraz-sandhal",
        img: Mian_Faraz,
        name: "Mian Faraz Sandhal",
        role: "CEO",
        alt: "Mian Faraz",
        description: "At Dynamic Leo, we excel in the digital space by forming close partnerships with our clients and delivering customized IT solutions.",
        phone: "+92 320 0002429",
        fb: "https://www.facebook.com/Mr.Nadym",
        insta: "https://www.instagram.com/nadymyousaf",
        linkedin: "https://www.linkedin.com/in/mnadeemyousaf",
        gmail: "nadeem@dynamicleo.com"
    },
    {
        nothing:"..."
    }
];
const CoreMembers = [
    {
        id: "tufail-shah",
        img: TufailShah,
        name: "Tufail Shah",
        role: "CTO",
        alt: "Tufail Shah",
        description: "I Develop Customized AI Automation Solutions For Industries.",
        phone: "+92 320 00002429",
        fb: "https://www.facebook.com/Mr.Nadym",
        insta: "https://www.instagram.com/nadymyousaf",
        linkedin: "https://www.linkedin.com/in/mnadeemyousaf",
        gmail: "nadeem@dynamicleo.com"
    },
    {
        id: "nadeem-yousaf",
        img: NadeemYousaf,
        name: "Nadeem Yousaf",
        role: "Vice President Marketing",
        alt: "Nadeem Yousaf",
        description: "As the Vice President of Marketing at Dynamic Leo Ecommerce and IT Solutions, Nadeem Yousaf brings a unique blend of technical expertise and marketing acumen to the team. With an MS in Computer Science, specializing in Artificial Intelligence from GIK Institute,  Nadeem combines his passion for technology with strategic marketing to drive business growth. Nadeem's strong communication skills, paired with his technical background, enable him to effectively bridge the gap between IT and business. He is committed to driving innovation and contributing to the growth of businesses through effective digital strategies.",
        phone: "+92 320 0002429",
        fb: "https://www.facebook.com/Mr.Nadym",
        insta: "https://www.instagram.com/nadymyousaf",
        linkedin: "https://www.linkedin.com/in/mnadeemyousaf",
        gmail: "nadeem@dynamicleo.com"
    }
];

const TeamMembers = [
    {
        id: "muhammad-mobeen",
        img: MuhammadMobeen,
        name: "Muhammad Mobeen",
        alt: "Muhammad Mobeen",
        role: "AI/ML Engineer",
        description: "I Develop Customized AI Automation Solutions For Industries.",
        phone: "+92 320 0002438",
        gmail: "muhammadmobeen@dynamicleo.com",
        linkedin: "https://www.linkedin.com/in/mobeen360/",
        github: "https://github.com/muhammad-mobeen"
    },
    {
        id: "ahmed-rohail-awan",
        img: AhmedRohailAwan,
        name: "Ahmed Rohail Awan",
        alt: "Ahmed Rohail Awan",
        role: "Full Stack Web Developer",
        description: "Expert in Crafting Custom Web Solutions Designed for the Unique Requirements of Different Industries.",
        phone: "+92 320 0002439",
        gmail: "ahmedrohailawan@dynamicleo.com",
        linkedin: "https://www.linkedin.com/in/ahmedrohailawan/",
        github: "https://github.com/ahmedrohailawan"
    },
    {
        id: "ammar-zafar",
        img: AmmarZafar,
        name: "Ammar Zafar",
        alt: "Ammar Zafar",
        role: "UI/UX Designer",
        description: "Expert in Developing Custom Design Solutions that Cater to the Distinct Requirements of Diverse Industries.",
        phone: "+92 320 0002418",
        gmail: "ammarzafar@dynamicleo.com",
        linkedin: "https://www.linkedin.com/in/ammar-zafar-776b05241/"
    },
    {
        id: "bint-e-zainab-laraib",
        img: BintEZainabLaraib,
        name: "Bint E Zaina Laraib",
        alt: "Bint E Zaina Laraib",
        role: "Sales and Digital Marketing Associate",
        description: "I am a Google-certified digital strategist and marketer with 4 years of experience. I specialize in data-driven strategies and hold multiple certifications. My focus is on enhancing online presence and driving measurable business growth.",
        phone: "+92 320 0002417",
        gmail: "bint-e-zainab@dynamicleo.com",
        linkedin: "https://www.linkedin.com/in/zainab-laraib-",
        github: "https://github.com/bintezainablaraib"
    },
    {
        id: "zain-ul-abaideen",
        img: ZainulAbaideen,
        name: "Zain ul Abaideen",
        alt: "Zain ul Abaideen",
        role: "React Developer",
        description: "Expert in Developing Automation Solutions Specifically for React.",
        linkedin: "https://www.linkedin.com/in/zainulabaideen",
        github: "https://github.com/zainulabaideen"
    },
    {
        id: "muhammad-bin-nasir",
        img: MuhammadBinNasir,
        name: "Muhammad Bin Nasir",
        alt: "MuhammadBinNasir",
        role: "Content Writing - Intern",
        linkedin: "https://www.linkedin.com/in/muhammad-bin-nasir",
    },
];

export { CoreMembers,CEO, TeamMembers };
