import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import mission from '../../Assets/VisionAndMission/1.svg'
import Level from '../../Assets/VisionAndMission/2.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';

function VisionAndMission() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    const handleLinkClick = () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };
    return (
        <div className='w-full py-8 overflow-hidden'>
            <div className='w-full sectionlayout poppins'>
                <div>
                    <div className='flex flex-col md:flex-row pt-[5rem] md:pt-[5.9rem] '>
                        <div className='md:w-1/2' data-aos="fade-up" data-aos-duration="1500" >
                            <div>
                                <h2 style={{ fontSize: 'clamp(20px,5vw,32px)' }} className='font-bold  leading-tight text-[#DC5025] mb-[1rem]'>Our Mission</h2>
                                <h4 style={{ fontSize: 'clamp(25px,3vw,48px)' }} className='uppercase font-bold  leading-tight'>Pursuing <br /> excellence through <br /> Digital Innovation</h4>
                                <p style={{ fontSize: 'clamp(16px,1.5vw, 20px)' }} className='text-[#6C6C6C] w-[80%] md:w-[75%] font-medium pl-1 pt-[1.5rem] md:pt-[2rem]'>Committed to pushing boundaries and delivering innovative solutions that set new standards in excellence.</p>
                            </div>
                            <div className='md:flex pt-[1.5rem] md:pt-[2rem]'>
                                <Link to="/about" onClick={handleLinkClick}>
                                    <button style={{ fontSize: 'clamp(15px,1.5vw,18px)' }} className='py-[12px] px-[20px] hover:border-none border border-[#DC5025] text-[#DC5025] hover:bg-gradient-to-r from-[#FEC163] to-[#DE4313] hover:text-white rounded-[8px] font-semibold mr-6 mb-4 md:mb-0'>Read More </button>
                                </Link>
                            </div>
                        </div>
                        <div className='flex items-center justify-end md:w-1/2' data-aos="zoom-in-left" data-aos-duration="1500" >
                            <img src={mission} alt="Misssion" className='w-[50%] md:w-[70%] relative -top-16 md:static' />
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row-reverse md:pt-[5.9rem] relative -top-16 md:static '>
                        <div className='md:w-1/2 mt-[5rem]' data-aos="fade-up" data-aos-duration="2000" >
                            <div className='flex items-center justify-end'>
                                <div className='md:w-[80%]'>
                                    <div>
                                        <h4 style={{ fontSize: 'clamp(25px,3vw,48px)' }} className='uppercase font-bold  leading-tight'>Take Your Business <br />to the Next Level</h4>
                                        <p style={{ fontSize: 'clamp(16px,1.5vw, 20px)' }} className='text-[#6C6C6C] w-[80%] md:w-[75%] font-medium pl-1 pt-[1.5rem] md:pt-[2rem]'>Want to take your online presence to the next level? Get in touch for a complimentary consultation and find out how Dynamic Leo can help you reach your digital marketing goals.</p>
                                    </div>
                                    <div className='md:flex pt-[1.5rem] md:pt-[2rem]'>
                                        <Link to="/contact" onClick={handleLinkClick}>
                                            <button style={{ fontSize: 'clamp(15px,1.5vw,18px)' }} className='py-[12px] px-[20px] hover:border-none border border-[#DC5025] text-[#DC5025] hover:bg-gradient-to-r from-[#FEC163] to-[#DE4313] hover:text-white rounded-[8px] font-semibold mr-6 mb-4 md:mb-0'>Join Now </button>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='md:w-1/2'>
                            <div className='flex items-center justify-center md:justify-start' data-aos="zoom-in-right" data-aos-duration="1500" >
                                <img src={Level} alt="Level" className='w-[80%] md:w-[70%]' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default VisionAndMission