import React, {useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import contact_hero from '../../Assets/Hero/contact.jpg'
import './contact.css'

function Hero() {
    useEffect(() => {
        AOS.init({ duration: 1000 }); 
      }, []);
    return (
        <div className='w-full bg-[#FFEABD] overflow-hidden'>
            <div className='w-full poppins mx-auto max-w-[1640px]'>
                <div className='flex'>
                    <div className='w-[30%] flex items-center justify-center'>
                        <h1 style={{ fontSize: 'clamp(20px,3vw,60px)' }} className='text-center uppercase text-[#DF5932] font-bold px-[1rem] md:p-[2rem]' data-aos="fade-right">Contact Us</h1>
                    </div>
                    <div className='w-[70%] h-[40vh] md:h-[60vh] flex items-center justify-end' data-aos="fade-left">
                        <img src={contact_hero} alt="Contact Us" className='hero_img_p object-cover h-[40vh] md:h-[60vh] w-[90%]' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero