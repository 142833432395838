import React,{useEffect} from 'react'
import DMWork from '../../../Data/Solutions/AiBased'
import AOS from 'aos';
import 'aos/dist/aos.css';
import BelowSection from './BelowSection';

function Work() {
    useEffect(() => {
        AOS.init({ duration: 1000 }); 
      }, []);
    return (
        <div  className='w-full bg-[#f6f6f6] py-8'>
            <div className='w-full sectionlayout poppins'>
                <h6 className='uppercase font-semibold text-[16px] text-[#DF5932] text-center pb-3'>What we Offer</h6>
                <h5 style={{ fontSize: 'clamp(22px,2.5vw,42px)' }} className='font-semibold text-[#491609] text-center w-[80%] md:w-[60%] mx-auto '>Redefine Efficiency with Innovative AI Tools</h5>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-12 gap-5'>
                    {DMWork.map((service, index) => (
                        <div key={index} className='px-4 md:px-8 bg-white py-9 rounded-xl drop-shadow-lg' data-aos="fade-up" >
                            <div className='flex items-center justify-center pt-2'>
                                <img src={service.img} alt={service.alt} className='h-[230px]' />
                            </div>
                            <h6 style={{ fontSize: 'clamp(18px,1.5vw,23px)' }} className='font-semibold text-[#DF5932] text-center mt-5 mb-2'>{service.title}</h6>
                        </div>
                    ))}
                </div>
            <BelowSection />
               
            </div>
        </div>
    )
}

export default Work