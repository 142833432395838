import React,{useEffect} from 'react'
import hero_img from '../../../Assets/Hero/maintanance.svg'
import greater_arrow_white from '../../../Assets//icons/greater_arrow_white.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';
function Hero() {
    useEffect(() => {
        AOS.init({ duration: 1000 }); 
      }, []);
    return (
        <div className='w-full bg-[#FFEABD] max-h-[100vh] pb-[2rem] overflow-hidden'>
            <div className='w-full sectionlayout poppins'>
                <div className='block md:flex pt-[2rem] md:pt-[2.5rem]'>
                    <div className='md:w-[53%]' data-aos="fade-up-right" >
                        <div className='text-[#491609]'>
                            <h1 style={{ fontSize: 'clamp(30px,4.6vw,70px)'}} className='uppercase font-bold  leading-tight'>Keeping Your <span className='text-[#DF5932] '>IT Solutions   </span>running Smooth</h1>
                            <p style={{ fontSize: 'clamp(16px,1.5vw, 20px)'}} className='text-[#6C6C6C] w-[80%] md:w-[88%] font-medium pl-1 pt-[1.5rem] md:pt-[2rem]'>Maintain peak performance with our IT maintenance services, offering expert care and proactive solutions to keep your systems running without interruptions.</p>
                        </div>
                        <div className='md:flex pt-[1.5rem] md:pt-[2rem]'>
                            <button style={{ fontSize: 'clamp(15px,1.5vw,18px)'}} className='py-[12px] px-[20px] bg-gradient-to-r from-[#FEC163] to-[#DE4313] text-white rounded-[8px] font-semibold mr-6 mb-4 md:mb-0'>Learn More <img src={greater_arrow_white} alt="Greater Arrow" className='pl-3 inline-block h-[15px]'/></button>
                        </div>
                    </div>
                    <div className='flex items-center justify-end md:w-1/2' data-aos="fade-left" >
                        <img src={hero_img} alt="Maintanance" className='w-[100%]' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero