import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { CoreMembers, TeamMembers, CEO } from '../../Data/TeamMembers'
import { Link } from 'react-router-dom';

function Team() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    const handleLinkClick = () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };
    return (
        <div className='w-full'>
            <div className='w-full sectionlayout poppins'>
                <div>
                    <h6 style={{ fontSize: 'clamp(25px,3.5vw,50px)' }} className='text-[#491609] font-medium'>Meet our team</h6>
                    <h4 style={{ fontSize: 'clamp(25px,4vw,60px)' }} className='text-[#DF5932] font-bold' data-aos="fade-up">Creative. Curious. Committed</h4>
                    <p style={{ fontSize: 'clamp(17px,1.5vw,20px)' }} className='text-[#6c6c6c] w-[75%] pl-1' data-aos="fade-up">Conquering complex E-commerce and IT projects with Dynamic Leo’s expertise.</p>
                </div>
                <div className='flex md:flex-wrap justify-center gap-5 mt-8 mb-16'>
                    {CEO.map((member, index) => (
                        member.nothing ? (
                            <div className='bg-gray-100 rounded-lg pb-4 px-2 md:px-4 xl:px-8' data-aos="fade-up" key={index}></div>
                        ) : (
                            <Link to={`/team/${member.id}`} className='cursor-pointer' onClick={handleLinkClick} key={index}>
                                <div className='bg-gray-100 rounded-lg pb-4 px-2 md:px-4 xl:px-8' data-aos="fade-up">
                                    <div className='flex items-center justify-center'>
                                        <img src={member.img} alt={member.alt} className='h-[250px] md:h-[300px] team_img_p mt-3' />
                                    </div>
                                    <h6 className='font-semibold text-[18px] md:text-[23px] text-[#6c6c6c] text-center mt-3'>{member.name}</h6>
                                    <p className='text-[15px] md:text-[17px] text-[#6c6c6c] text-center mb-3'>{member.role}</p>
                                </div>
                            </Link>
                        )
                    ))}
                </div>

                <div className='grid grid-cols-1 md:flex md:flex-wrap md:justify-center gap-5 mt-8 mb-16'>
                    {CoreMembers.map((member, index) => (
                        <Link to={`/team/${member.id}`} className='cursor-pointer' onClick={handleLinkClick}>
                            <div key={index} className='bg-gray-100 rounded-lg pb-4 md:px-16' data-aos="fade-up">
                                <div className='flex items-center justify-center'>
                                    <img src={member.img} alt={member.alt} className='h-[300px] team_img_p mt-3' />
                                </div>
                                <h6 className='font-semibold text-[18h6x] md:text-[23px] text-[#6c6c6c] text-center mt-3'>{member.name}</h6>
                                <p className='text-[15px] md:text-[17px] text-[#6c6c6c] text-center mb-3'>{member.role}</p>
                            </div>
                        </Link>
                    ))}
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-8 mb-16'>
                    {TeamMembers.map((member, index) => (
                        <Link to={`/team/${member.id}`} className='cursor-pointer' onClick={handleLinkClick}>
                            <div key={index} className='bg-gray-100 rounded-lg pb-4 px-4 md:px-8' data-aos="fade-up">
                                <div className='flex items-center justify-center'>
                                    <img src={member.img} alt="" className='h-[300px] team_img_p mt-3' />
                                </div>
                                <h6 className='font-semibold text-[18px] md:text-[23px] text-[#6c6c6c] text-center mt-3'>{member.name}</h6>
                                <p className='text-[15px] md:text-[17px] text-[#6c6c6c] text-center mb-3'>{member.role}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>)
}

export default Team