import React from 'react'
import Hero from './Hero'
import Description from './Description'
import Team from './Team'

function About() {
  return (
    <>
    <Hero/>
    <Description/>
    <Team/>
    </>
  )
}

export default About