import  React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import hero_img from '../../Assets/Hero/home_hero.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Hero() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);
  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };
  return (
    <div className='w-full bg-[#FFEABD] min-h-[90vh] overflow-hidden'>
      <div className='w-full sectionlayout poppins md:flex'>
        <div className='md:w-1/2' data-aos="fade-up" >
          <div className='pt-[2rem] md:pt-[2.5rem]'>
            <h4 className='tracking-[10px] md:tracking-[16px] text-[#6c6c6c] text-[16px] md:text-[24px] font-medium pl-1 uppercase'>Driving Your</h4>
            <h1 className='text-[50px] sm:text-[40px] md:text-[70px] text-[#DF5932]  font-semibold  leading-tight uppercase'>Digital Excellence.</h1>
            <h6 className='text-[#6c6c6c] text-[17px] md:text-[20px] w-[75%] md:[50%] font-medium pl-1 pt-4'>Unlock Innovation with Dynamic Leo's Premier IT Solutions</h6>
          </div>
          <div className='md:flex mt-4 md:mt-8'>
            <Link to='/contact' onClick={handleNav}>
            <button className='py-[12px] px-[20px] bg-gradient-to-r from-[#FEC163] to-[#DE4313] text-white text-[15px] md:text-[16px] rounded-[8px] font-semibold mr-6 block mb-4 md:mb-0'>Get Started</button>
            </Link> 
          </div>
        </div>
        <div className='md:w-1/2 flex items-center justify-end md:justify-start md:mt-[5rem] h-[400px] md:h-auto' data-aos="fade-up-left" >
          <img className='' src={hero_img} alt="Home Hero" />
        </div>
      </div>
    </div>
  )
}

export default Hero
