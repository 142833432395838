import React, { useEffect } from "react";
import img1 from "../../../Assets/solutions/AppDevelopment/belowSection.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';
const BelowSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);
  return (
    <div className="flex justify-center my-10 overflow-hidden">
      <div className=" w-full bg-gradient-to-r from-[#F05F57] to-[#360940] text-white rounded-[12px] pt-5" data-aos='fade-up'>
        <p
          className="flex justify-end pr-4"
          style={{ fontSize: "clamp(18px,1.5vw,23px)" }}
          data-aos='fade-left'
        >
          Your Vision, Our Code
        </p>

        <div className="flex relative md:justify-center px-10 flex-col space-y-8 md:space-y-0  mt-9 md:flex-row">
          <div className="w-full md:w-[25%] justify-center flex md:justify-end" >
            <div className=" text-center lg:w-[80%] flex items-center flex-col md:mt-6">
              <h2
                style={{ fontSize: "clamp(22px,2.3vw,36px)" }}
                className="font-bold capitalize"
                data-aos='fade-right'
              >
                Ready to Craft Your app?
              </h2>
              <p
                style={{ fontSize: "clamp(15px,1.5vw,17px)" }}
                className="font-medium mt-3 w-[80%] md:mt-12"
                data-aos='fade-right'
              >
                Transform Your Ideas into Intuitive Mobile Experiences
              </p>
            </div>
          </div>
          <div className="w-full md:w-[75%] flex items-end justify-end md:justify-normal" data-aos='fade-left-up'>
            <img
              src={img1}
              alt="Dynamic Leo"
              className="w-[90%] max-h-[90vh]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BelowSection;
