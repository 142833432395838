import React from "react";
import { CoreMembers, TeamMembers, CEO } from "../../Data/TeamMembers";
import { useParams } from "react-router-dom";
import phone from "../../Assets/icons/teamicons/phone.svg";
import fb from "../../Assets/icons/teamicons/fb.svg";
import linkedin from "../../Assets/icons/teamicons/linkedin.svg";
import insta from "../../Assets/icons/teamicons/insta.svg";
import github from "../../Assets/icons/teamicons/github.svg";
import gmail from "../../Assets/icons/teamicons/gmail.svg";

function SpecificMember() {
  const { id } = useParams();
  const allMembers = [...CoreMembers, ...CEO, ...TeamMembers];
  const member = allMembers.find((m) => m.id === id);

  if (!member) {
    return <div>Member not found!</div>;
  }
  return (
    <div className="min-h-[100vh] px-4 poppins w-full bg-[#FFEABD] ">
      <div className="flex items-center justify-center"><img src={member.img} alt={member.alt} className="h-[300px] team_img_p mt-3" /></div>
      <div className="flex flex-col justify-center ">
        <h2 className="font-bold  text-center mt-3 text-[#3E4E41]" style={{ fontSize: "clamp(30px,2.5vw,42px)" }}>{member.name}</h2>
        <p className=" font-medium  text-[#3E4E41] text-center" style={{ fontSize: "clamp(22px,2vw,36px)" }}>{member.role}</p>
        {member.phone && (<p className="text-[#3E4E41] flex justify-center my-3" style={{ fontSize: "clamp(19px,1.3vw,26px)" }}><span className="mr-1 rotate-[-14deg] mt-0.5"><img src={phone} alt="Phone" /></span>{" "}{member.phone}</p>)}
        <p className="text-[#766047] my-3 leading-10 md:w-[80%] text-center mx-auto" style={{ fontSize: "clamp(20px,1.4vw,28px)" }}>{member.description}</p>
        <h3 className="text-[#3E4E41] font-semibold mx-auto mb-3" style={{ fontSize: "clamp(30px,2.5vw,42px)" }}>Let's Connect</h3>
        <div className="flex space-x-6 mt-2 justify-center pb-8">
          {member.gmail && (<a href={`mailto:${member.gmail}`} target="_blank" rel="noreferrer"><img src={gmail} alt="Gmail" className="h-[50px]" /></a>)}
          {member.fb && (<a href={member.fb} target="_blank" rel="noreferrer"><img src={fb} alt="Facebook" className="h-[50px]" /></a>)}
          {member.insta && (<a href={member.insta} target="_blank" rel="noreferrer"><img src={insta} alt="Instagram" className="h-[50px]" /></a>)}
          {member.linkedin && (<a href={member.linkedin} target="_blank" rel="noreferrer"><img src={linkedin} alt="Linkedin" className="h-[50px]" /></a>)}
          {member.github && (<a href={member.github} target="_blank" rel="noreferrer"><img src={github} alt="Github" className="h-[50px]" /></a>)}
        </div>
      </div>
    </div>
  );
}

export default SpecificMember;
