import React, { useEffect } from 'react'
import logo from '../../Assets/It-Solutions-Dynamic-Leo.png'
import { Link } from 'react-router-dom'
import right_arrow_white from '../../Assets/icons/right_arrow_white.svg'
import Social from '../../Components/Social/Social'
import AOS from 'aos';
import 'aos/dist/aos.css';


function Footer() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const handleLinkClick = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };
  return (
    <div className='w-full bg-[#ffffff] pb-5'>
      <div className='w-full sectionlayout poppins'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-[#6c6c6c] py-8'>
          <div data-aos="fade-up">
            <Link to='/' onClick={handleLinkClick}>
              <img src={logo} alt="It-Solutions-Dynamic-Leo" className='max-h-[9vh] max-w-[70%] md:max-h-[60px] md:max-w-[220px]' />
            </Link>
            <p className='mt-4 text-[14px]'>Gulberg Green Islamabad</p>
            <p className='mt-4 text-[14px]'>info@dynamicleo.com</p>
            <p className='mt-4 text-[14px]'>+92 304 1110898</p>
          </div>
          <div data-aos="fade-up">
            <h5 className='text-[18px] font-semibold mt-8 md:mt-1 mb-4 relative'>Quick Links <span className='absolute left-0 bottom-[-20px] w-[50px] h-[4px] bg-[#b6861f]'></span></h5>
            <Link to="/" onClick={handleLinkClick}><p className='mt-[35px] md:mt-[55px] text-[14px]'>Home</p></Link>
            <Link to="/solutions" onClick={handleLinkClick}><p className='mt-3 text-[14px]'>Solutions</p></Link>
            <Link to="/about" onClick={handleLinkClick}><p className='mt-3 text-[14px]'>About Us</p></Link>
            <Link to="/contact" onClick={handleLinkClick}><p className='mt-3 text-[14px]'>Contact Us</p></Link>
          </div>
          <div data-aos="fade-up">
            <h5 className='text-[18px] font-semibold  mt-8 lg:mt-1 mb-4 relative'>Solutions <span className='absolute left-0 bottom-[-20px] w-[50px] h-[4px] bg-[#b6861f]'></span></h5>
            <Link to="/digital-marketing" onClick={handleLinkClick}><p className='mt-[35px] text-[14px]'>Digital Marketing</p></Link>
            <Link to="/web-development" onClick={handleLinkClick}><p className='mt-3 text-[14px]'>Web Development</p></Link>
            <Link to="/app-development" onClick={handleLinkClick}><p className='mt-3 text-[14px]'>App Development</p></Link>
            <Link to="/ai-based-solutions" onClick={handleLinkClick}><p className='mt-3 text-[14px]'>AI Based Solutions</p></Link>
            <Link to="/" onClick={handleLinkClick}><p className='mt-3 text-[14px]'>Domain & Hosting</p></Link>
            <Link to="/maintainance" onClick={handleLinkClick}><p className='mt-3 text-[14px]'>Maintenance</p></Link>            
          </div>
          <div data-aos="fade-up">
            <h5 className='text-[18px] font-semibold  mt-8 lg:mt-1 mb-4 relative'>Subscribe <span className='absolute left-0 bottom-[-20px] w-[50px] h-[4px] bg-[#b6861f]'></span></h5>
            <div className='md:w-full mt-[45px] flex mb-5'>
              <input type="text" placeholder='Get product updates' className='p-4 w-[80%] border font-normal text-[14px] rounded-tl-[10px] rounded-bl-[10px] focus:border-blue-500 focus:outline-none' />
              <button type='submit' className='p-4 w-[20%] font-normal text-[14px] bg-[#478EFF] flex items-center justify-center rounded-tr-[10px] rounded-br-[10px]'><img src={right_arrow_white} alt="right_arrow" /></button>
            </div>
            <div className='flex md:block items-center justify-center'>
              <Social />
            </div>
          </div>
        </div>
        <p className="text-[#6c6c6c] text-center md:text-left">© 2024 Dynamic Leo. All rights reserved</p>
      </div>
    </div>
  )
}
export default Footer
